export const environment = {
    production: false,
    apiUrl: 'https://dev-eus2-zipcrimservices-func.azurewebsites.net/api',
    tenantId : '12beff2e-11d9-4a1f-8112-150c902e7393',
    isLocal : false,
    exploreDirectory : 'ExploreData\\Outbound\\',
    explorePointerDirectory : 'ExploreData_Pointer\\Outbound\\',
    url: "https://dev-diq.cisive.com",
    clientId: '3df7feb1-a99e-4bf6-b9fe-abe118672f6d'
  };

  //https://dev-eus2-zipcrimservices-func.azurewebsites.net/api